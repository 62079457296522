import { lazy, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// import { element } from 'prop-types';
import SuperAdminLayout from '../layouts/super-admin/dashboard';
import AuthGuard from '../guards/AuthGuard';
import SuperAdminGuard from '../guards/SuperAdminGuard';
import { SUPER_ADMIN_DASHBOARD } from '../config';
import LoadingScreen from '../components/LoadingScreen';
// import { element } from 'prop-types';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/super-admin/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

const Dashboard = Loadable(lazy(() => import('../pages/users/super-admin/dashboard/Dashboard')));
const Roles = Loadable(lazy(() => import('../pages/user-management/Role/Roles')));
const RolePermission = Loadable(lazy(() => import('../pages/user-management/Role/RolePermission')));
const RoleOperation = Loadable(lazy(() => import('../pages/user-management/Role/RoleOperation')));
const Users = Loadable(lazy(() => import('../pages/users/super-admin/User/Users')));
const UserOperation = Loadable(lazy(() => import('../pages/users/super-admin/User/UserOperation')));
const UserAccount = Loadable(lazy(() => import('../pages/profile/UserAccount')));
const Languages = Loadable(lazy(() => import('../pages/users/super-admin/masters/Language/Languages')));
const LanguageOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Language/LanguageOperation')));
const Faqs = Loadable(lazy(() => import('../pages/users/super-admin/masters/FAQ/FAQs')));
const FaqOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/FAQ/FAQOperation')));
const NotificationTypeOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/NotificationType/NotificationTypeOperation'))
);
const NotificationTypes = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/NotificationType/NotificationTypes'))
);
// const Categories = Loadable(lazy(() => import('../pages/users/super-admin/masters/Category/Categories')));
// const CategoryOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Category/CategoryOperation')));
const Videos = Loadable(lazy(() => import('../pages/users/super-admin/masters/Video/Videos')));
const VideoOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Video/VideoOperation')));
const PageContent = Loadable(lazy(() => import('../pages/users/super-admin/masters/PageContent/PageContents')));
const PageContentOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/PageContent/PageContentOperation'))
);
const GlobalSettings = Loadable(lazy(() => import('../pages/users/super-admin/masters/GlobalSettings/GlobalSettings')));
const GlobalSettingsOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/GlobalSettings/GlobalSettingsOperation'))
);
const Admissions = Loadable(lazy(() => import('../pages/users/super-admin/masters/Admission/Admission')));
const AdmissionOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/Admission/AdmissionOperation'))
);
const ChairmenMessage = Loadable(lazy(() => import('../pages/users/super-admin/masters/chairmen_message/Messages')));
const ChairmenMessageOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/chairmen_message/MessageOperation'))
);
const Facilities = Loadable(lazy(() => import('../pages/users/super-admin/masters/Facility/Facilities')));
const FacilityOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Facility/FacilityOperation')));
const FacilityImages = Loadable(lazy(() => import('../pages/users/super-admin/masters/Facility/View/FacilityImages')));
const Activities = Loadable(lazy(() => import('../pages/users/super-admin/masters/Activity/Activities')));
const ActivityOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Activity/ActivityOperation')));
const ContactUs = Loadable(lazy(() => import('../pages/users/super-admin/masters/ContactUs/ContactUss')));
const ContactUsOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/ContactUs/ContactUsOperation'))
);
const Events = Loadable(lazy(() => import('../pages/users/super-admin/masters/Events/Events')));
const EventOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Events/EventOperation')));

const Achievements = Loadable(lazy(() => import('../pages/users/super-admin/masters/Achievement/Achievements')));
const AchievementOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/Achievement/AchievementsOperation'))
);

const Cbse = Loadable(lazy(() => import('../pages/users/super-admin/masters/CBSE/CBSEs')));
const CbseOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/CBSE/CBSEOperation')));
const CollectionItems = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/CBSE/collectionItem/CollectionItems'))
);
const CollectionItemOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/CBSE/collectionItem/CollectionItemOperation'))
);
const SocialLinks = Loadable(lazy(() => import('../pages/users/super-admin/masters/SocialLink/Social_links')));
const SocialLinkOperations = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/SocialLink/social_linkOperation'))
);

const PrincipalDesk = Loadable(lazy(() => import('../pages/users/super-admin/masters/PrincipalDesk/PrincipalDesks')));

const PrincipalDeskOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/PrincipalDesk/PrincipalDeskOperation'))
);

const Banners = Loadable(lazy(() => import('../pages/users/super-admin/masters/Banner/Banners')));
const BannerOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Banner/BannerOperation')));

const AdmissionGuideline = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/AdmissionGuideline/AdmissionGuidelines'))
);
const AdmissionGuidelineOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/AdmissionGuideline/AdmissionGuidelineOperation')))


const AdmissionGuidelineItems = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/AdmissionGuideline/admissionGuidelineItem/AdmissionGuidelineItems'))
)
const AdmissionGuidelineItemOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/AdmissionGuideline/admissionGuidelineItem/AdmissionGuidelineItemOperation'))
)

const AuthorityMessages = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/AuthorityMessage/AuthorityMessages'))
);
const AuthorityMessageOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/AuthorityMessage/AuthorityMessageOperation'))
);

const UpdateContactUsDetails = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/ContactUs/UpdateContactUsDetails/UpdateContactUsDetails')));
const UpdateContactUsDetailOperation = Loadable(
    lazy(() => import('../pages/users/super-admin/masters/ContactUs/UpdateContactUsDetails/UpdateContactUsDetailOperation')));



const SuperAdminRoutes = {
    path: '/super-admin',
    element: (
        <AuthGuard>
            <SuperAdminGuard>
                <SuperAdminLayout />
            </SuperAdminGuard>
        </AuthGuard>
    ),
    children: [
        { element: <Navigate to={SUPER_ADMIN_DASHBOARD} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        {
            path: 'profile',
            children: [{ path: 'edit-profile', element: <UserAccount /> }],
        },
        {
            path: 'user-management',
            children: [
                { path: 'roles', element: <Roles /> },
                { path: 'role/:id/permission', element: <RolePermission /> },
                { path: 'role/add', element: <RoleOperation /> },
                { path: 'role/:id/edit', element: <RoleOperation /> },
                { path: 'role/:id/view', element: <RoleOperation /> },
                { path: 'users', element: <Users /> },
                { path: 'user/add', element: <UserOperation /> },
                { path: 'user/:id/edit', element: <UserOperation /> },
                { path: 'user/:id/view', element: <UserOperation /> },
               
            ],
        },
        {
            path: 'master',
            children: [
                { path: 'languages', element: <Languages /> },
                { path: 'language/add', element: <LanguageOperation /> },
                { path: 'language/:id/edit', element: <LanguageOperation /> },
                { path: 'language/:id/view', element: <LanguageOperation /> },
                { path: 'faqs', element: <Faqs /> },
                { path: 'faq/add', element: <FaqOperation /> },
                { path: 'faq/:id/edit', element: <FaqOperation /> },
                { path: 'faq/:id/view', element: <FaqOperation /> },
                { path: 'admissions', element: <Admissions /> },
                { path: 'admission/add', element: <AdmissionOperation /> },
                { path: 'admission/:id/edit', element: <AdmissionOperation /> },
                { path: 'admission/:id/view', element: <AdmissionOperation /> },
             
                { path: 'videos', element: <Videos /> },
                { path: 'videos/add', element: <VideoOperation /> },
                { path: 'videos/:id/edit', element: <VideoOperation /> },
                { path: 'videos/:id/view', element: <VideoOperation /> },
                { path: 'notification-types', element: <NotificationTypes /> },
                { path: 'notification-types/add', element: <NotificationTypeOperation /> },
                { path: 'notification-types/:id/edit', element: <NotificationTypeOperation /> },
                { path: 'notification-types/:id/view', element: <NotificationTypeOperation /> },

                { path: 'messages', element: <ChairmenMessage /> },
                { path: 'messages/add', element: <ChairmenMessageOperation /> },
                { path: 'messages/:id/edit', element: <ChairmenMessageOperation /> },
                { path: 'messages/:id/view', element: <ChairmenMessageOperation /> },
               
                { path: 'cbse', element: <Cbse /> },
                { path: 'cbse/add', element: <CbseOperation /> },
                { path: 'cbse/:id/edit', element: <CbseOperation /> },
                { path: 'cbse/:id/view', element: <CbseOperation /> },
                { path: 'cbse/:id/items', element: <CollectionItems /> },
                { path: 'cbse/:id/items/add', element: <CollectionItemOperation /> },
                { path: 'cbse/:id/items/:itemId/edit', element: <CollectionItemOperation /> },
                { path: 'cbse/:id/items/:itemId/view', element: <CollectionItemOperation /> },

                {path: 'achievements',element:<Achievements/>},
                {path: 'achievements/add', element:<AchievementOperation/>},
                {path: 'achievements/:id/edit', element:<AchievementOperation/>},
                {path: 'achievements/:id/view', element:<AchievementOperation/>},

                { path: 'banners', element: <Banners /> },
                { path: 'banners/add', element: <BannerOperation /> },
                { path: 'banners/:id/edit', element: <BannerOperation /> },
                { path: 'banners/:id/view', element: <BannerOperation /> },

                { path: 'facilities', element: <Facilities /> },
                { path: 'facilities/add', element: <FacilityOperation /> },
                { path: 'facilities/:id/edit', element: <FacilityOperation /> },
                { path: 'facilities/:id/view', element: <FacilityOperation /> },
                { path: 'facilities/:id/images', element: <FacilityOperation /> },

                { path: 'activities', element: <Activities /> },
                { path: 'activities/add', element: <ActivityOperation /> },
                { path: 'activities/:id/edit', element: <ActivityOperation /> },
                { path: 'activities/:id/view', element: <ActivityOperation /> },
                { path: 'activities/:id/images', element: <ActivityOperation /> },

                { path: 'contact-us', element: <ContactUs /> },
                { path: 'contact-us/add', element: <ContactUsOperation /> },
                { path: 'contact-us/:id/edit', element: <ContactUsOperation /> },
                { path: 'contact-us/:id/view', element: <ContactUsOperation /> },

                { path: 'contact-us-details', element: <UpdateContactUsDetails /> },
                { path: 'contact-us-details/add', element: <UpdateContactUsDetailOperation /> },
                { path: 'contact-us-details/:id/edit', element: <UpdateContactUsDetailOperation /> },
                { path: 'contact-us-details/:id/view', element: <UpdateContactUsDetailOperation /> },

                { path: 'social_links', element: <SocialLinks /> },
                { path: 'social_links/add', element: <SocialLinkOperations /> },
                { path: 'social_links/:id/edit', element: <SocialLinkOperations /> },
                { path: 'social_links/:id/view', element: <SocialLinkOperations /> },
                { path: 'events', element: <Events /> },
                { path: 'events/add', element: <EventOperation /> },
                { path: 'events/:id/edit', element: <EventOperation /> },
                { path: 'events/:id/view', element: <EventOperation /> },
                { path: 'events/:id/images', element: <EventOperation /> },
                { path: 'principal-desk', element: <PrincipalDesk /> },
                { path: 'principal-desk/add', element: <PrincipalDeskOperation /> },
                { path: 'principal-desk/:id/edit', element: <PrincipalDeskOperation /> },
                { path: 'principal-desk/:id/view', element: <PrincipalDeskOperation /> },

                { path: 'authority-messages', element: <AuthorityMessages /> },
                { path: 'authority-messages/add', element: <AuthorityMessageOperation /> },
                { path: 'authority-messages/:id/edit', element: <AuthorityMessageOperation /> },
                { path: 'authority-messages/:id/view', element: <AuthorityMessageOperation /> },

                { path: 'admission-guidelines', element: <AdmissionGuideline /> },
                { path: 'admission-guidelines/add', element: <AdmissionGuidelineOperation /> },
                { path: 'admission-guidelines/:id/edit', element: <AdmissionGuidelineOperation /> },
                { path: 'admission-guidelines/:id/view', element: <AdmissionGuidelineOperation /> },
                { path: 'admission-guidelines/:id/items', element: <AdmissionGuidelineItems /> },
                { path: 'admission-guidelines/:id/items/add', element: <AdmissionGuidelineItemOperation /> },
                { path: 'admission-guidelines/:id/items/:itemId/edit', element: <AdmissionGuidelineItemOperation /> },
                { path: 'admission-guidelines/:id/items/:itemId/view', element: <AdmissionGuidelineItemOperation /> },
            ],
        },

        {
            path: 'page-content',
            children: [
                { path: '', element: <PageContent /> },
                { path: 'add', element: <PageContentOperation /> },
                { path: ':id/edit', element: <PageContentOperation /> },
                { path: ':id/view', element: <PageContentOperation /> },
            ],
        },
        {
            path: 'global-settings',
            children: [
                { path: '', element: <GlobalSettings /> },
                { path: 'add', element: <GlobalSettingsOperation /> },
                { path: ':id/edit', element: <GlobalSettingsOperation /> },
                { path: ':id/view', element: <GlobalSettingsOperation /> },
            ],
        },
    ],
};

export default SuperAdminRoutes;
